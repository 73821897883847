export const pageHeading =
  "Our clients are very satisfied to take our services";
export const headerImg = "./images/inner-header-bg.png";
export const list = [
  {
    icon: "./images/client-pic.png",
    comment:
      "This is test comment. This is test comment. This is test comment. This is test comment. ",
    name: "Dummy Name",
  },
  {
    icon: "./images/client-pic.png",
    comment:
      "This is test comment. This is test comment. This is test comment. This is test comment. ",
    name: "Dummy Name",
  },
  {
    icon: "./images/client-pic.png",
    comment:
      "This is test comment. This is test comment. This is test comment. This is test comment. ",
    name: "Dummy Name",
  },
  {
    icon: "./images/client-pic.png",
    comment:
      "This is test comment. This is test comment. This is test comment. This is test comment. ",
    name: "Dummy Name",
  },
  {
    icon: "./images/client-pic.png",
    comment:
      "This is test comment. This is test comment. This is test comment. This is test comment. ",
    name: "Dummy Name",
  },
  {
    icon: "./images/client-pic.png",
    comment:
      "This is test comment. This is test comment. This is test comment. This is test comment. ",
    name: "Dummy Name",
  },
  {
    icon: "./images/client-pic.png",
    comment:
      "This is test comment. This is test comment. This is test comment. This is test comment. ",
    name: "Dummy Name",
  },
  {
    icon: "./images/client-pic.png",
    comment:
      "This is test comment. This is test comment. This is test comment. This is test comment. ",
    name: "Dummy Name",
  },
];
