export const topheaderText =
  "Buy organic - dals & pulses/spices online at best price";

export const facebookLink = "/";
export const logo = {
  imgPath: "./images/logo.svg",
  imgAlt: "Farm To You Fresh",
  linkPath: "/",
};
export const phoneDetail = {
  number: "+919780780995",
  text: "Call us now",
};
