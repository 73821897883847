export const pageHeading =
  "On a quest to bring together and closer to you all things Organic";
export const headerImg = "./images/inner-header-bg.png";
export const list = [
  {
    key: "1",
    img: "./images/blog-img1.png",
    date: "March 17, 2023",
    title: "Best guide to shopping for organic ingredients.",
    buttonText: "Read more",
    buttonLink: "/",
  },
  {
    key: "2",
    img: "./images/blog-img1.png",
    date: "March 17, 2023",
    title: "Best guide to shopping for organic ingredients.",
    buttonText: "Read more",
    buttonLink: "/",
  },
  {
    key: "3",
    img: "./images/blog-img1.png",
    date: "March 17, 2023",
    title: "Best guide to shopping for organic ingredients.",
    buttonText: "Read more",
    buttonLink: "/",
  },
  {
    key: "4",
    img: "./images/blog-img1.png",
    date: "March 17, 2023",
    title: "Best guide to shopping for organic ingredients.",
    buttonText: "Read more",
    buttonLink: "/",
  },
  {
    key: "5",
    img: "./images/blog-img1.png",
    date: "March 17, 2023",
    title: "Best guide to shopping for organic ingredients.",
    buttonText: "Read more",
    buttonLink: "/",
  },
  {
    key: "6",
    img: "./images/blog-img1.png",
    date: "March 17, 2023",
    title: "Best guide to shopping for organic ingredients.",
    buttonText: "Read more",
    buttonLink: "/",
  },
  {
    key: "7",
    img: "./images/blog-img1.png",
    date: "March 17, 2023",
    title: "Best guide to shopping for organic ingredients.",
    buttonText: "Read more",
    buttonLink: "/",
  },
  {
    key: "8",
    img: "./images/blog-img1.png",
    date: "March 17, 2023",
    title: "Best guide to shopping for organic ingredients.",
    buttonText: "Read more",
    buttonLink: "/",
  },
  {
    key: "9",
    img: "./images/blog-img1.png",
    date: "March 17, 2023",
    title: "Best guide to shopping for organic ingredients.",
    buttonText: "Read more",
    buttonLink: "/",
  },
  {
    key: "10",
    img: "./images/blog-img1.png",
    date: "March 17, 2023",
    title: "Best guide to shopping for organic ingredients.",
    buttonText: "Read more",
    buttonLink: "/",
  },
  {
    key: "11",
    img: "./images/blog-img1.png",
    date: "March 17, 2023",
    title: "Best guide to shopping for organic ingredients.",
    buttonText: "Read more",
    buttonLink: "/",
  },
  {
    key: "12",
    img: "./images/blog-img1.png",
    date: "March 17, 2023",
    title: "Best guide to shopping for organic ingredients.",
    buttonText: "Read more",
    buttonLink: "/",
  },
];
