export const productListHeading1 = "Pulses Product";
export const pageHeading = "Product Detail";
export const headerImg = "./images/inner-header-bg.png";
export const productList1 = [
  {
    key: "1",
    productImg: "./images/pulse1.png",
    name: "Arhar Dal",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "2",
    productImg: "./images/pulse2.png",
    name: "Mung Bean",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "3",
    productImg: "./images/pulse3.png",
    name: "Lentil Pulse",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "4",
    productImg: "./images/pulse4.png",
    name: "Horse Gram",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "5",
    productImg: "./images/pulse1.png",
    name: "Arhar Dal",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "6",
    productImg: "./images/pulse2.png",
    name: "Mung Bean",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "7",
    productImg: "./images/pulse3.png",
    name: "Lentil Pulse",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "8",
    productImg: "./images/pulse4.png",
    name: "Horse Gram",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "9",
    productImg: "./images/pulse1.png",
    name: "Arhar Dal",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "10",
    productImg: "./images/pulse2.png",
    name: "Mung Bean",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "11",
    productImg: "./images/pulse3.png",
    name: "Lentil Pulse",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "12",
    productImg: "./images/pulse4.png",
    name: "Horse Gram",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
];

export const productListHeading2 = "Spices Product";
export const productList2 = [
  {
    key: "1",
    productImg: "./images/spice1.png",
    name: "Garam Masala",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "2",
    productImg: "./images/spice2.png",
    name: "Turmeric",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "3",
    productImg: "./images/spice3.png",
    name: "Red chilli",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "4",
    productImg: "./images/spice4.png",
    name: "Black Cardamom",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "5",
    productImg: "./images/spice1.png",
    name: "Garam Masala",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "6",
    productImg: "./images/spice2.png",
    name: "Turmeric",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "7",
    productImg: "./images/spice3.png",
    name: "Red chilli",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "8",
    productImg: "./images/spice4.png",
    name: "Black Cardamom",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "9",
    productImg: "./images/spice1.png",
    name: "Garam Masala",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "10",
    productImg: "./images/spice2.png",
    name: "Turmeric",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "11",
    productImg: "./images/spice3.png",
    name: "Red chilli",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "12",
    productImg: "./images/spice4.png",
    name: "Black Cardamom",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "13",
    productImg: "./images/spice1.png",
    name: "Garam Masala",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "14",
    productImg: "./images/spice2.png",
    name: "Turmeric",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "15",
    productImg: "./images/spice3.png",
    name: "Red chilli",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
  {
    key: "16",
    productImg: "./images/spice4.png",
    name: "Black Cardamom",
    price: "20.00/-",
    buttonText: "Buy Now",
    buttonLink: "/",
    inStock: false,
  },
];
