export const pageHeading = "Feel free to contact with us any time";
export const headerImg = "./images/inner-header-bg.png";
export const list = [
  {
    icon: "./images/phone-icon.png",
    title: "Phone Number:",
    text: "+91 9780780995",
  },
  {
    icon: "./images/mail-icon.png",
    title: "Mail Address:",
    text: "sales@farmtoyoufresh.com",
  },
];
