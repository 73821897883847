export const slides = [
  {
    key: "1",
    contentLayout: "centerText",
    image: "./images/slide1.jpg",
    h1: "Fresh Farm For You",
    h1Color: "#ffffff",
    h2: "",
    h2Color: "#000000",
    text: "",
    textColor: "#000000",
    buttonText: "View Products",
    buttonLink: "#/products",
  },
  {
    key: "2",
    contentLayout: "sideContext",
    image: "./images/slide3.png",
    h1: "Helping you",
    h1Color: "#6D7C17",
    h2: "getting better life",
    h2Color: "#000000",
    text: "",
    textColor: "#000000",
    buttonText: "View Products",
    buttonLink: "#/products",
  },
  {
    key: "3",
    contentLayout: "sideContext",
    image: "./images/slide2.jpg",
    h1: "Organic & Fresh",
    h1Color: "#038D06",
    h2: "Pulses, Spices, Herbs",
    h2Color: "#000000",
    text: "Assured Quality Every Time",
    textColor: "#000000",
    buttonText: "View Products",
    buttonLink: "#/products",
  },
];
